.gsui-alert-wrap {
  border: 1px solid #0073bb;
  background-color: #f1faff;
  padding: 12px 20px;
  display: flex;
  align-items: flex-start;
  &.margin {
    margin: 0 0 15px 0;
  }
  &.error {
    border: 1px solid #aab7b8;
    background-color: #ffffff;
  }
  &.warning {
    border: 1px solid #d13212;
    background-color: #fdf3f1;
  }
  .red {
    color: #d13212;
  }
  .icon {
    color: #0073bb;
    width: 30px;
    .error-text {
      color: $error;
    }
  }
  .text {
    padding-left: 5px;
    flex: 1;
    &-title {
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }
    &-content {
      padding-top: 2px;
      font-size: $font-size-sm;
    }
  }
  .space-between {
    display: flex;
    justify-content: space-between;
  }
}
